<template>
  <div class="bill">
    <headbar :title="$t('我的收益')"></headbar>

    <div class="select" @click="show = true">
      <div>{{ formatDate(date) }}</div>
      <img src="@/assets/img/base/more.png" class="more-icon" />
    </div>

    <div class="list">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="bouns-item" v-for="item in list" :key="item.id">
          <div class="bouns-cell">
            <div>{{ $t(item.lxName) }}</div>
            <div style="color: #2cb065;">{{ item.jine }}</div>
          </div>
          <div class="bouns-cell bouns-label">
            <div>{{ abbr(item.yuserid, 4, 4) }}</div>
            <div>{{ item.edate }}</div>
          </div>
          <div class="bouns-cell bouns-label" v-if="item.bz != '-'">
            <div>{{ $t(item.bz) }}</div>
          </div>
        </div>
      </van-list>
      <empty :text="$t('暂无记录')" v-if="list.length == 0" />
    </div>

    <van-popup v-model:show="show" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @confirm="dateConfirm"
        @cancel="show = false"
      />
    </van-popup>

    <div class="cp-popup">
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker :confirm-button-text="$t('确定')" :cancel-button-text="$t('取消')" :columns="filterList"
          @cancel="showPicker = false" @confirm="onConfirm" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Empty from '@/components/Empty'
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

import { Tab, Tabs, List, Popup, Picker, Sticky, DatetimePicker } from "vant";

export default {
  name: 'Bill',
  components: {
    Empty,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [Popup.name]: Popup,
    [Picker.name]: Picker,

    [Sticky.name]: Sticky,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      list: ref([]),
      loading: ref(false),
      finished: ref(false),
      showPicker: ref(false),
      filterList: ref([]),
      shouyi: ref({}),

      active: ref(3),

      date: ref(""),
      currentDate: ref(""),
      show: ref(false)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.date = new Date()
    this.currentDate = new Date()
    if(this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    back() {
      this.$router.back()
    },
    onConfirm(e) {
      if (e.val == 99) {
        this.list = this.allList
      } else {
        this.list = this.allList.filter(item => {
          return item.lx == e.val
        })
      }
      this.showPicker = false
    },
    onClick(e) {
      this.active = e
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    formatDate(date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      return y + '-' + m + '-' + d
    },
    dateConfirm() {
      this.date = this.currentDate
      this.show = false
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/Bonus/SourceList",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          uid: _this.us.id,
          lx: _this.active,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          date: _this.formatDate(_this.date)
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          console.log(data)
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.back-wrap {
  padding: 0 12px;
}

.list {
  padding: 10px 10px 0;
}

.list-name {
  display: flex;
}

.list-name>div {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-name img {
  margin-left: 8px;
  width: 14px;
}

.list-val {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.list-val>div {
  flex: 1;
}

.cell {
  margin-top: 15px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.total {
  padding: 15px 15px 0;
}

.total-row {
  margin-top: 6px;
  display: flex;
}

.total-item {
  padding-bottom: 10px;
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(231, 228, 228, 0.7);
  border-bottom: 1px solid rgba(231, 228, 228, 0.7);
}

.total-item:last-child {
  border-right: 0;
}

.item-border {
  border-right: 1px solid rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.total-title {
  font-weight: bold;
  font-size: 15px;
}

.bouns-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #1b1b1b;
}

.bouns-cell {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
}

.bouns-label {
  color: #aaaaaa;
}

.cp-popup /deep/ .van-picker {
  background: #352D45 !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 2px solid #f56ef9;
  border-bottom: 2px solid #f56ef9;
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #f56ef9;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}

.select {
  margin: 10px 10px 0;
  padding: 15px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1b1b1b;
  color: #fff;
}

.more-icon {
  width: 14px;
}
</style>